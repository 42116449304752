<template>
	<div class="setPersonalinfor">
		<!-- 个人信息栏 -->
		<div class="setItem" v-if="isShow">
			<div class="icon-box">
				<i class="el-icon-setting" style="color:#409eff"></i>
			</div>
			<el-card class="box-card boxCard-box">
				<div slot="header" class="clearfix">
					<span>个人信息栏</span>
				</div>

				<div class="deployItem">
					<label class="label" style="display: inline-block;width:116px;text-align: right;">背景图: </label>
					<img v-if="dataList.headModule.BackUrlComplete" :src="dataList.headModule.BackUrlComplete" alt="" class="imgClass">
					<el-upload style="width:370px;" :action="imgApi"  :before-upload="beforeUploadfu"  :on-success="handleAvatarSuccessfu" :file-list="fileList"
					 multiple accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu" :show-file-list="false">
						<el-button type="text" size="small" class="changeImgbtn">更改背景图</el-button>
						<span class="asctions">图片建议尺寸长750px，高364px</span>
					</el-upload>
				</div>

				<div class="deployItem">
					<label class="label" style="display: inline-block;width:170px;text-align: right;">头像与昵称位置: </label>
					<el-radio v-model="dataList.headModule.Location" style='margin-right: 56px;' :label="0" @change="radioChange">居中</el-radio>
					<el-radio v-model="dataList.headModule.Location" :label="1" @change="radioChange">靠左</el-radio>
				</div>

				<div class="deployItem">
					<label class="label" style="display: inline-block;width:170px;text-align: right;">会员入口: </label>
					<el-radio v-model="dataList.headModule.IsShowVipEntrance" style='margin-right: 56px;' :label="1"  @change="radioChange">显示</el-radio>
					<el-radio v-model="dataList.headModule.IsShowVipEntrance" :label="0"  @change="radioChange">隐藏</el-radio>
				</div>
        
        <div class="deployItem assets-item">
					<label class="label" style="display: inline-block;width:170px;text-align: right;">资产信息: </label>
          <div>
            <el-checkbox style='width:80px;' v-model="dataList.headModule.IsShowPoint" @change="radioChange">积分</el-checkbox>
            <el-checkbox style='width:80px;' v-model="dataList.headModule.IsShowCoupon" @change="radioChange">优惠券</el-checkbox>
            <br>
            <!-- <el-checkbox style='width:80px;' v-model="dataList.headModule.IsShowRebate" @change="radioChange">订单立返</el-checkbox> -->
            <el-checkbox style='width:80px;' v-model="dataList.headModule.IsShowBalance" @change="radioChange">余额</el-checkbox>
          </div>
				</div>

			</el-card>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	export default {
		props: {
			isShowInfor: {
				type: Boolean,
				default: false
			},
			dataList: {
				type: Object
			}
		},
		data() {
			return {
				isShow: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				fileList: [],
				headModule:{}
			}
		},
		created() {
		},
		beforeMount() {
			
		},
		methods: {
			beforeUploadfu(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isJPG) {
					this.$message.error('请上传png,jpeg或gif格式的文件!');
				}
				if (!isLt1M) {
					this.$message.error('背景图请控制在1M以内!');
				}
				return isJPG && isLt1M;
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
				this.dataList.headModule.BackUrl  = file.response[0]
				this.dataList.headModule.BackUrlComplete =this.imgUrl+file.response[0]
				this.$emit('changeData', this.dataList);
				this.$forceUpdate()

			},
			radioChange(){
				this.$emit('changeData', this.dataList);
			},
			handleRemovefu(file, fileList) {},
		},
		watch: {
			isShowInfor(val) {
				this.isShow = val
			},


		}

	}
</script>

<style lang="less" scoped>
	.setPersonalinfor {
		.setItem{
			margin-top:90px;
		}
    .assets-item{
      display:flex;
      align-items: baseline;
    }
		.imgClass {
			width: 300px;
			height: 145px;
			vertical-align: top;
		}

		.changeImgbtn {
			margin: 15px 0 0 114px;
			font-size: 14px;
		}

		.asctions {
			font-size: 12px;
			color: #999;
			margin-left: 10px;
		}

	}
</style>
