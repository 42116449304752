<template>
	<div class="main">
		<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/personalOrderStyle-img.png" v-if="!dataList.IsShowOrderModel" class="mask" alt="">
		<div class="personalOrderStyle" v-else>		
			<div class="order-titles order-titles1">
				<div class="bolds">全部订单</div>
				<div class="arrows"></div>
			</div>
			
			<div class="order-titles">
				<div v-for="(item,index) in dataList.orderModuleList" :key="index">
					<img :src="item.IconUrlComplete" alt="" class="order-icon">
					<div style="margin-top:5px">{{item.IconName}}</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'	
	export default{
		props: {
			dataList: {
				type: Object
			}
		},
		data(){
			return{
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
			}
		}
	}
</script>

<style lang="less">
.main{
	position: relative;
	height: 121px;
	.mask{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.personalOrderStyle{
		width:100%;
		background: #fff;
		font-size: 13px;
		.order-titles{
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			-moz-box-pack: space-between;
			-webkit--moz-box-pack: space-between;
			box-pack: space-between;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			padding:15px 5%;
			text-align: center;
		}
		.bolds{
			font-weight: bold;
		}
		.order-titles1{
			border-bottom: 1px solid #eee;
		}
		.arrows {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-top: 1px solid #333;
			border-right: 1px solid #333;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			vertical-align: 1px;
		}
		
		.order-icon{
			width:25px;
			height:25px;
		}
	}

}
</style>
