<template >
	<div class="setSetinfor">
		<div class="setItem" v-if="showSet" :style="{'margin-top':(dataList.headModule.IsShowWallet?'860px':'715px')}" >
			<div class="icon-box">
				<i class="el-icon-setting" style="color:#409eff"></i>
			</div>
			<el-card class="box-card boxCard-box">
				<div slot="header" class="clearfix">
					<span>工具栏</span>
				</div>
				<label class="label">工具栏展示样式: </label>
				<el-radio v-model="dataList.toolsModule.ShowWay" :label="0" @change="radioChange">列表</el-radio>
				<el-radio v-model="dataList.toolsModule.ShowWay" :label="1" @change="radioChange">九宫格</el-radio>
				<div class="asctions">图标建议尺寸长80px，高80px；透明背景</div>
				<draggable class="product-list" :list="dataList.toolsModule.toolDtoList" :options="{ animation: 300, handle: '.setParent' }" >

					<div v-for="(item,index) in dataList.toolsModule.toolDtoList" :key="index">
						<!--  -->
						<div  class="setParent"  @mouseover="showReason(item.Id)"  @mouseleave="hideReason(item.Id)" >

							<el-popover
								v-if="item.Id== 2 && isAgentModel== 1"
								placement="bottom"
								width='300'
								trigger="manual"
								content="该工具仅可在推手模式内可配置，商城当前使用的是代理层级模式"
								v-model="reasonPopover">
							</el-popover>

							<el-checkbox v-model="item.IsShow" :disabled='(isShowList.length == 1 && isShowList.includes(item.Id) ) || (item.Id== 2&& isAgentModel== 1)'></el-checkbox>

							<div class="childBox">
								<label class="label setSamename">工具名称: </label>
								<el-input :disabled='item.Id== 2&& isAgentModel== 1' v-model="item.ToolName" maxlength="7" clearable class="setnames"></el-input>

								<div class="filter-container" style="margin-top:10px;padding-bottom: 0;">
									<div class="filter-item setSamename" style="margin-right:10px">图标：</div>
									<div class="filter-item">
										<img  v-if="item.IconUrlComplete" :src="item.IconUrlComplete" alt="" class="imgClass">
									</div>
									<div class="filter-item" @click="uploadImg(item,index)">
										<el-upload   :action="imgApi"  :before-upload="beforeUploadfu" :on-success="handleAvatarSuccessfu" :file-list="fileList" multiple accept="image/png,image/jpeg,image/jpg,image/gif"
										:on-remove="handleRemovefu" :show-file-list="false">
										 <!--  -->
											<el-button type="text" size="small" class="changeImgbtn" v-if="!(item.Id== 2 && isAgentModel== 1)">更改图标</el-button>
										</el-upload>
									</div>
								</div>
								<div style="padding-left:57px;margin-top: 10px;">链接：{{item.OldToolName}}</div>
							</div>
						</div>
					</div>

				</draggable>

			</el-card>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import draggable from 'vuedraggable';
	export default {
		components: {
			draggable
		},
		props: {
			isShowSet: {
				type: Boolean,
				default: false
			},
			dataList: {
				type: Object
			}
		},
		data() {
			return {
				showSet: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				uploadIndex: 0,
				fileList: [],
				isAgentModel: 0,
				reasonPopover: false,
			}
		},
		created() {
			this.isAgentModel = window.localStorage.getItem('mlmzDistributionModel')
		},
		computed: {
			isShowList(){
				let list = []
				this.dataList.toolsModule.toolDtoList.forEach((t)=>{
					if(t.IsShow){
						list.push(t.Id)
					}
				})
				return list
			}
		},
		methods: {
			showReason(id){
				if(id==2 && this.isAgentModel == 1 && !this.reasonPopover){
					this.reasonPopover = true
				}
			},
			hideReason(id){
				if(id==2 && this.isAgentModel == 1){
					this.reasonPopover = false
				}
			},
			radioChange() {
				this.$emit('changeData', this.dataList);
			},
			// 上传图片
			uploadImg(item, index) {
				// console.log("123456")
				this.uploadIndex = index
			},
			beforeUploadfu(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isJPG) {
					this.$message.error('请上传png,jpeg或gif格式的文件!');
				}
				if (!isLt1M) {
					this.$message.error('图标请控制在1M以内!');
				}
				return isJPG && isLt1M;
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
				this.dataList.toolsModule.toolDtoList[this.uploadIndex].IconUrl = file.response[0]
				this.dataList.toolsModule.toolDtoList[this.uploadIndex].IconUrlComplete = this.imgUrl + file.response[0]
				this.$emit('changeData', this.dataList);
				this.$forceUpdate()
			},
			handleRemovefu(file, fileList) {},
		},
		watch: {
			isShowSet(val) {
				this.showSet = val
			},

		},

	}
</script>

<style lang="less" scoped>
	.setSetinfor {
    font-size: 14px;
    
    .product-list{
      max-height: 600px;
      overflow: auto;
    }
		.asctions {
			font-size: 12px;
			color: #999;
			margin-bottom: 15px;
		}

		.setParent {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			padding: 20px;
			background: #f5f5f5;
			margin-bottom: 15px;
		}

		.childBox {
			margin-left: 10px;
		}

		.setnames {
			width: 200px;
			height: 30px;
		}

		.setSamename {
			display: inline-block;
			width: 100px;
			text-align: right;
		}

		.imgClass {
			width: 50px;
			height: 50px;
		}

	}
</style>
