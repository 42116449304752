<template>
  <div>
    <div v-if="dataList.PictureAdvertiseModuleList&&dataList.PictureAdvertiseModuleList.length">
      <el-carousel :height="height+'px'" arrow="never" class="carousel">
        <el-carousel-item v-for="(item,i) in dataList.PictureAdvertiseModuleList" :key="i">
          <div class="flexcenter" style="overflow:hidden">
            <img :src="imgUrl+item.Picture" style="width:100%" ref="carouselimg" alt="" @load="imaload">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box" v-else>
      <div class="default-font">点击编辑广告位</div>
    </div>
  </div>
</template>

<script>
import config from '@/config/index'	
export default {
  props: {
    dataList: {
      type: Object
    }
  },
  data(){
    return{
      imgUrl: config.IMG_BASE,
      imgApi: config.UPLOAD_IMG,
      height:200
    }
  },
  watch:{
    'dataList.PictureAdvertiseModuleList':{
      handler(val){
        this.imaload()
      },
      deep:true
    }
  },
  mounted () {
    this.imaload()
  },
  methods: {
    imaload(){
      this.$nextTick(()=>{
        if(!this.$refs.carouselimg||!this.$refs.carouselimg.length) return
        let heightlist = this.$refs.carouselimg.map(v=>v.offsetHeight)
        this.height = Math.max(...heightlist)
        if(this.height>200){
          this.height = 200
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.carousel{
  ::v-deep .el-carousel__button{
    border-radius: 50%;
    height: 5px;
    width: 5px;
  }
}
.flexcenter{
  height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.box{
  width:100%;
  height: 90px;
  background: #EBF8FD;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .default-font{
    font-size: 14px;
    color: #88C4DC;
    line-height: 20px;
    text-align: center;
  }
}
</style>