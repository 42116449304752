<template>
	<div class="personalInvite">
    <img v-show="dataList.IsShowMyRecommend" src="https://cdn.dkycn.cn/Images/wgj/images/melyshop-addInforimg.jpg" class="melyshopAddInforimg">
    <img v-show="!dataList.IsShowMyRecommend" src="http://cdn.dkycn.cn/images/melyshop/melyshop-addInforimg2.png" class="melyshopAddInforimg">
	</div>
</template>

<script>
	export default {
		props: {
			dataList: {
				type: Object
			}
		},
		beforeMount() {
		},
		data() {
			return {
			}
		},
	}
</script>

<style lang="less">
	.personalInvite {
    .melyshopAddInforimg{
			display: block;
			width:100%;
		}
	}
</style>
