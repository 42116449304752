<template>
  <div>
    <div class = "box">
      <img v-show="dataList.IsShowWithdrawModule" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/personalwithdrawal20240222.png">
      <img v-show="!dataList.IsShowWithdrawModule" style="height:100%;width:100%" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/personalwithdrawal20240222-2.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Object
    }
  }
}
</script>

<style lang = "less" scoped>
  .box{
    background: white;
    /* padding: 20px; */
    padding: 0px;
    margin: 0px;
    vertical-align:bottom;
    height: 108px;
    img{
      width:100%
    }
  }
</style>