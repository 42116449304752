<template>
	<div class="personalContent">
		<div class="headInformations">
			<img :src="dataList.headModule.BackUrlComplete" alt="" class="headBgimg">
			<!-- 居中展示 -->
			
			<div class="head-name" v-if="dataList.headModule.Location==0">
				<img src="https://cdn.dkycn.cn/melyshop/image/20200506-825da136-40ff-4008-9842-73334b8709cb.png" alt="" class="commentImgs">
				<div>微信昵称</div>
			</div>

			<!-- 靠左展示 -->
			<div class="head-lefts" v-if="dataList.headModule.Location==1">
				<img src="https://cdn.dkycn.cn/melyshop/image/20200506-825da136-40ff-4008-9842-73334b8709cb.png" alt="" class="commentImgs commentImgs1">
				<span style="margin-left:10px">微信昵称</span>
			</div>

			<div class="details-moneys">
				<div class="details-top"  v-if="dataList.headModule.IsShowVipEntrance">
					<div class="bolds">商城名称</div>
					<div class="becomeCenter">
						成为会员
						<span class="arrows"></span>
					</div>
				</div>
				
				<div class="details-bottom" v-if='dataList.headModule.IsShowPoint||dataList.headModule.IsShowCoupon||dataList.headModule.IsShowRebate||dataList.headModule.IsShowBalance'>
					<div class="details-bottom-item" v-if="dataList.headModule.IsShowPoint">
						<div  class="bolds">0</div>
						<div class="discounts">积分</div>
					</div>
					
					<div class="details-bottom-item" v-if="dataList.headModule.IsShowCoupon">
						<div  class="bolds">0</div>
						<div class="discounts">优惠券</div>
					</div>
					
					<!-- <div class="details-bottom-item" v-if="dataList.headModule.IsShowRebate">
						<div  class="bolds">0</div>
						<div class="discounts">返现</div>
					</div> -->
					
					<div class="details-bottom-item" v-if="dataList.headModule.IsShowBalance">
						<div  class="bolds">0</div>
						<div class="discounts">余额</div>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	export default {
		props: {
			dataList: {
				type: Object
			}
		},
		beforeMount() {
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				personalDataList:[]
			}
		},
		watch:{
			dataList(val){
				this.personalDataList=val
			}
		}
	}
</script>

<style lang="less">
	.personalContent {
		font-size: 13px;

		.headInformations {
			position: relative;
		}

		.headBgimg {
			width: 100%;
			display: block;
			height:180px;
		}

		.head-name {
			position: absolute;
			top: 15%;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			font-size: 13px;
			color: #fff;
			text-align: center;
		}

		.head-lefts {
			position: absolute;
			top: 15%;
			left: 15px;
			font-size: 13px;
			color: #fff;
		}

		.commentImgs {
			width: 60px;
			height: 60px;
			border-radius: 100%;
			margin-bottom: 5px;
		}

		.commentImgs1 {
			vertical-align: middle;
		}

		.details-moneys {
			width: 90%;
			margin:auto;
			border-radius: 5px;
			background: #fff;
			position: relative;
			.details-top {
				width: 100%;
				border-radius: 5px 5px 0 0;
				margin-top:-45px;
				padding:10px;
				background:linear-gradient(left,#edc685,#fdf6ea);
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-justify-content: space-between;
				justify-content: space-between;
				-moz-box-pack: space-between;
				-webkit--moz-box-pack: space-between;
				box-pack: space-between;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
			}
			.details-bottom{
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-justify-content: space-between;
				justify-content: space-between;
				-moz-box-pack: space-between;
				-webkit--moz-box-pack: space-between;
				box-pack: space-between;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				padding:15px;
        text-align: center;
        .details-bottom-item{
          flex: 1 1 auto;
        }
			}
			.bolds{
				font-weight: bold;
			}
			.discounts{
				margin-top:5px;
			}

			.becomeCenter {
				padding: 5px 10px;
				border-radius: 10px;
				background: #f1d197;

				.arrows {
					width: 7px;
					height: 7px;
					display: inline-block;
					border-top: 1px solid #333;
					border-right: 1px solid #333;
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					vertical-align: 1px;
				}
			}
		}
	}
</style>
