<template>
	<div class="personalSetstyle">
		<!-- 列表 -->
		<div v-for="(item,index) in dataList.toolsModule.toolDtoList" :key="index" v-if="dataList.toolsModule.ShowWay==0">
			<div class="setStyleItem" v-show="item.IsShow">
				<div>
					<img :src="item.IconUrlComplete " alt="" class="set-icon">
					{{item.ToolName}}
				</div>
				<div class="arrows"></div>
			</div>
		</div>

		<!-- 九宫格 -->
		<div class="nine-table" v-if="dataList.toolsModule.ShowWay==1">
			<div  v-for="(item,index) in dataList.toolsModule.toolDtoList" :key="index"  class="nineItem" :class="!item.IsShow?'nowidth':''">
				<div v-show="item.IsShow">
					<img :src="item.IconUrlComplete" alt="" class="order-icon">
					<div style="margin-top:5px">{{item.ToolName}}</div>
				</div>
			</div>
		</div>

	</div>


</template>

<script>
	import config from '@/config/index'
	export default {
		props: {
			dataList: {
				type: Object
			}
		},
		beforeMount() {
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
			}
		}
	}
</script>

<style lang="less">
	.personalSetstyle {
		font-size: 13px;
		background: #fff;

		.setStyleItem {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			-moz-box-pack: space-between;
			-webkit--moz-box-pack: space-between;
			box-pack: space-between;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			padding: 15px 5%;
			border-bottom: 1px solid #eee;
		}

		.bolds {
			font-weight: bold;
		}

		.set-icon {
			width: 18px;
			height: 18px;
			margin-right: 5px;
			vertical-align: -4px;
		}

		.arrows {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-top: 1px solid #333;
			border-right: 1px solid #333;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			vertical-align: 1px;
		}

		.nine-table {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			flex-wrap: wrap;
			-webkit-flex-wrap: wrap;
			-moz-flex-wrap: wrap;
			padding: 25px 0 15px;
			text-align: center;
		}

		.order-icon {
			width: 25px;
			height: 25px;
		}

		.nineItem {
			width: 33.33%;
			margin-bottom: 30px;
		}
		.nowidth{
			width: 0;
		}

	}
</style>
