<template>
	<div class="setOrderinfor">
		<div class="setItem" v-if="prictrueShow" :style="{'margin-top':(prictrueShow?'345px':'285px')}" >
			<div class="icon-box">
				<i class="el-icon-setting" style="color:#409eff"></i>
			</div>
			<el-card class="box-card boxCard-box">
				<div slot="header" class="clearfix">
					<span>图片广告位</span>
				</div>
        <div style="margin-bottom:20px">
          <div class="flex">
            <div class="asctions" style="font-weight:bold">添加图片</div>
            <span style="font-size:14px;color:#999999FF;margin-left:10px">最多添加5个广告，鼠标拖拽调整广告顺序</span>
          </div>
          <div style="margin:20px 0px 0px 0px">
            <draggable :list="dataList.PictureAdvertiseModuleList" :options="{ animation: 300, handle: '.graybox' }">
              <div class="demo" v-for="(v,i) in dataList.PictureAdvertiseModuleList" :key="i">
                <div class="graybox">
                  <img :src="imgUrl+v.Picture" alt="">
                  <div class="linkbox">
                    <span>链接：</span>
                    <el-dropdown trigger="click" placement="bottom-start" class="link-info" @command="handleCommand($event, v,i)">
                      <div class="link" :class="v.PagePath?'link-has':''">
                        <div :class="v.PagePath?'ellipsis':''" style="margin-right:10px">{{v.PagePath?v.PageName:'请选择页面'}}</div>
                        <i class="el-icon-close" style="margin-right:10px" v-if="v.PagePath" @click.stop="removepath(i)"></i>
                        <i class="el-icon-arrow-down" style="margin-right:10px"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">选择小程序内页面</el-dropdown-item>
                        <el-dropdown-item command="5">邀请视频号小店分享员</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div>
                  <div>
                    <el-upload :action="imgApi" :before-upload="beforeUploadfu"
                      :on-success="(e)=>{handleAvatarSuccessfu(e,i)}" :file-list="fileList"
                      accept="image/*" :show-file-list="false">
                      <el-button type="text">修改</el-button>
                    </el-upload>
                  </div>
                  <div>
                    <el-button type="text" style="color:#f56c6c" @click="del(i)">删除</el-button>
                  </div>
                </div>
              </div>
            </draggable>
            <div>
              <el-upload :action="imgApi" v-if="dataList.PictureAdvertiseModuleList.length<5" class="upload-class" :before-upload="beforeUploadfu"
                :on-success="(e)=>{handleAvatarSuccessfu(e,-1)}" :file-list="fileList"
                accept="image/*" :show-file-list="false" list-type="picture-card">
                <div class="upload-content">
                  <div class="flex-row font-14-606266">
                    <i class="el-icon-plus" style="font-size:14px;margin-right:10px"></i>添加图片
                  </div>
                  <div class="font-14-999999">建议图片宽750px；图片大小1M及以内；图片高度最大200px</div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div style="margin-bottom:20px">
          <div class="asctions" style="font-weight:bold">图片展示 
            <el-radio-group v-model="showtype" style="margin-left:10px">
              <el-radio :label="true">轮播海报</el-radio>
            </el-radio-group>
          </div>
        </div>
			</el-card>
		</div>
    
    <!-- 选择页面 -->
    <linkModal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false" key="1"></linkModal>

	</div>
</template>

<script>
	import config from '@/config/index'
	import linkModal from '@/views/components/linkModal';
	import draggable from 'vuedraggable';
	export default {
    components:{
      linkModal,
      draggable
    },
		props: {
			prictrueShow: {
				type: Boolean,
				default: false
			},
			dataList: {
				type: Object
			}
		},
		data() {
			return {
				showOrder: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				fileList: [],
				uploadIndex: -1,
        shopVisible:false,
        showtype:true
			}
		},
		methods: {
      handleCommand(e,v,i){
        // console.log(e)
        this.uploadIndex = i
        if(e=='1'){
          this.shopVisible = true
        }else if(e=='5'){
          this.dataList.PictureAdvertiseModuleList[this.uploadIndex].PageName = '邀请视频号小店分享员'
          this.dataList.PictureAdvertiseModuleList[this.uploadIndex].PagePath = 'path'
          this.dataList.PictureAdvertiseModuleList[this.uploadIndex].MenuType = 0
        }
        // console.log(this.dataList.PictureAdvertiseModuleList[this.uploadIndex])
      },
			beforeUploadfu(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isJPG) {
					this.$message.error('请上传png,jpeg或gif格式的文件');
				}
				if (!isLt1M) {
					this.$message.error('广告位图片大小请控制在1M及以内');
				}
				return isJPG && isLt1M;
			},
			//上传图片
			handleAvatarSuccessfu(file,e) {
        this.uploadIndex = e
        if(this.uploadIndex<0){
          this.dataList.PictureAdvertiseModuleList.push({
            Picture:file[0],
            MenuType:'',
            PageName:'',
            PagePath:''
          })
        }else{
          this.dataList.PictureAdvertiseModuleList[e].Picture = file[0]
        }
				// this.dataList.PictureAdvertiseModuleList[this.uploadIndex].Picture = file.response[0]
				// this.dataList.orderModuleList[this.uploadIndex].IconUrlComplete = this.imgUrl+file.response[0]
				// this.$emit('changeData', this.dataList);
				// this.$forceUpdate()

			},
      del(e){
        this.dataList.PictureAdvertiseModuleList.splice(e,1)
      },
      removepath(e){
        this.dataList.PictureAdvertiseModuleList[e].MenuType = ''
        this.dataList.PictureAdvertiseModuleList[e].PageName = ''
        this.dataList.PictureAdvertiseModuleList[e].PagePath = ''
        // console.log(this.dataList.PictureAdvertiseModuleList[e])
      },
      dataChange(e){
        console.log(e)
				this.dataList.PictureAdvertiseModuleList[this.uploadIndex].PagePath = this.geturl(e).url
				this.dataList.PictureAdvertiseModuleList[this.uploadIndex].PageName = e.text
				this.dataList.PictureAdvertiseModuleList[this.uploadIndex].MenuType = this.geturl(e).type
			},
			geturl(record){
				let data = {
					url:'',
					type:'',
				}
				if (record.type == "classify") {
          data.url = 'pages/productGroup/productGroup?id=' + record.data.Id
					data.type = 1
        } else if (record.type == "functional") {
          // 全部商品
					data.type = 2
          if (record.data.id == 1) {
            data.url = 'pages/index/index'
          } else if (record.data.id == 2) {
            data.url = 'pages/allProduct/allProduct'
          } else if (record.data.id == 3) {
            // 个人中心
            data.url = 'pages/personalCenter/mine/mine'
          } else if (record.data.id == 4) {
            // 购物车
            data.url = 'pages/shoppingCart/shoppingCart'
          } else if (record.data.id == 5) {
            // 积分中心
            data.url = 'pages/pointsCenter/pointsCenter'
          } else if (record.data.id == 6) {
            // 每日签到
            data.url = 'pages/userSign/userSign'
          } else if (record.data.id == 7) {
            // 客服
            data.url = 'pages/customerService/customerService'
          } else if (record.data.id == 8) {
            // 订单列表
            data.url = 'pages/order/myOrder/myOrder'
          } else if (record.data.id == 9) {
            // 分销礼包
            data.url = 'pages/invitation/invitationCard/invitationCard'
          }else if (record.data.id == 10) {
            // 成为会员
            data.url = 'pageA/pages/openSuccess/openSuccess'
          }else if (record.data.id == 11) {
            // 助力直播列表
            data.url = 'pageA/pages/live/live'
          }else if (record.data.id == 12) {
            // 扫码购
            data.url = 'pageA/pages/scan/scan'
          }else if (record.data.id == 13) {
            // 充值中心
            data.url = 'pageA/pages/balanceRecharge/balanceRecharge'
          }else if (record.data.id == 14) {
            // 群分享
            data.url = 'pageA/pages/groupShare/groupShare'
          }else if (record.data.id == 15) {
            // 全部信息页
            data.url = 'pageA/pages/information/information'
          }else if (record.data.id == 16) {
						// 短视频带货
						data.url =  'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
            // 积分码
            data.url = 'pageA/pages/receive-point/receive-point'
          }else if (record.data.id == 18) {
            // 舌诊
              data.url = 'pageC/pages/tongueDiagnosis/home'
          }else if (record.data.id == 19) {
						// 企店即创主页
						data.url = 'pageC/pages/AIGC/home'
					}
        } else if (record.type == "product") {
          data.url = 'pages/detail/detail?id=' + record.data.Id
					data.type = 3
        } else if (record.type == "custom") {
					data.type = 4
          data.url = 'pages/cusPage/cusPage?id=' + record.data.Id
        }else if (record.type == 'truntable'){
					data.type = 6
          data.url = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
        } else if (record.type == 'live'){
					data.type = 5
          data.url = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
        }else if (record.type == 'coupon'){
					data.type = 7
          data.url = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
        }else if (record.type == 'help') {
					data.type = 8
          data.url = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
        }else if (record.type == 'group') {
					data.type = 9
          data.url = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
        }else if (record.type == 'questionnaire') {
					data.type = 10
          data.url = 'pageA/pages/questionnaire/questionnaire?&referer=2&id=' + record.data.Id;
        }else if (record.type == 'taketest') {
					data.type = 11
					data.url = `pageC/pages/goodnessTest/home?id=` + record.data.Id
				}else if (record.type == 'contentgroup') {
					data.type = 12
					data.url = `pageA/pages/information/informationGroup?id=` + record.data.Id;
				}else if (record.type == 'singlecontent') {
					data.type = 13
					data.url = `pageA/pages/information/detail?id=` + record.data.Id;
				}else if (record.type == 'helpPro') {
					data.type = 14
					data.url = `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId
				}
				return data
			},
		},
	}
</script>

<style lang="less" scoped>
.demo{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  .graybox{
    flex:1;
    padding: 10px;
    background: #F5F5F5;
    min-height: 90px;
    display: flex;
    align-items: center;
    gap: 10px;
    img{
      width: 70px;height: 70px;
    }
    .linkbox{
        display: flex;
        align-items: center;
        font-size: 14px;
        color:#606266;
        line-height: 2;
      .link{
        display: flex;
        align-items: center;
        font-size: 14px;
        color:#409eff;
        line-height: 2;
        cursor: pointer;
      }
      .link-has{
        display: flex;
        align-items: center;
        background: #409eff;
        font-size: 14px;
        color: white;
        padding: 0px 5px;
        max-width: 200px;
      }
    }
  }
}
	.asctions{
    color: #606266;
    font-size: 14px;
  }
  .upload-class{
    ::v-deep .el-upload{
      width: 536px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border:1px dashed #DDDDDDFF;
      border-radius: 0px;
    }
    .upload-content{
      .flex-row{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .font-14-606266{
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
  }
  .font-14-999999{
    font-size: 14px;
    color: #909399;
    line-height: 1.5;
  }
  .flex{
    display: flex;
  }
</style>
