<template>
	<div class="personalMall" v-loading="loading">
		<div class="content-box">
			<div class="phonePage">
				<div class="pageItem" style="margin-bottom:0;" :class="isShowTitle?'pageItemactive':'pageItem'" @click="changeAction(1)">
					<img src="https://cdn.dkycn.cn/images/melyshop/pc-mallHomeBg.png" class="mallHomeBg">
					<div class="pageNames">{{dataList.PageTitle}}</div>
				</div>

				<div class="pageItem" :class="isShowInfor?'pageItemactive':'pageItem'" @click="changeAction(2)">
					<personalContent v-if="dataIsready" :dataList="dataList"></personalContent>
				</div>

				<div class="pageItem" :class="prictrueShow?'pageItemactive':'pageItem'" @click="changeAction(7)">
					<picture-advertising v-if="dataIsready" :dataList="dataList"></picture-advertising>
				</div>

				<div class="pageItem" :class="isShowOrder?'pageItemactive':'pageItem'" @click="changeAction(3)">
					<personalOrderStyle v-if="dataIsready" :dataList="dataList"></personalOrderStyle>
				</div>
				<!-- 可提现 -->
				<div class="pageItem" :class="isShowwithdrawal?'pageItemactive':'pageItem'" @click="changeAction(6)">
					<personalwithdrawal v-if="dataIsready" :dataList="dataList"></personalwithdrawal>
				</div>

				<div class="pageItem" :class="isShowInvite?'pageItemactive':'pageItem'" @click="changeAction(4)">
					<personalInvite v-if="dataIsready" :dataList="dataList"></personalInvite>
				</div>
				
				
				<div class="pageItem" :class="isShowSet?'pageItemactive':'pageItem'" @click="changeAction(5)">
					<personalSetstyle v-if="dataIsready" :dataList="dataList"></personalSetstyle>
				</div>
			</div>
			<div class="right-editePage">
				<!-- 设置标题 -->
				<div class="setItem" v-if="isShowTitle">
					<div class="icon-box">
						<i class="el-icon-setting" style="color:#409eff"></i>
					</div>
					<el-card class="box-card boxCard-box">
						<div slot="header" class="clearfix">
							<span>页面标题</span>
						</div>
						<div>
							<span>页面标题</span>
							<el-input v-model="dataList.PageTitle" maxlength="6" clearable style="width:280px;height:30px;margin-left:25px;"></el-input>
						</div>
					</el-card>
				</div>

				<!-- 个人信息栏 -->
				<setPersonalinfor :isShowInfor="isShowInfor" v-show="isShowInfor" :dataList="dataList" @changeData="changeData"></setPersonalinfor>
				<!-- 订单信息栏 -->
				<setOrderinfor :isShowOrder="isShowOrder" v-show="isShowOrder" :dataList="dataList" @changeData="changeData"></setOrderinfor>
				<!-- 我的邀请人栏 -->
				<setInvite :isShowInvite="isShowInvite" v-show="isShowInvite" :dataList="dataList" @changeData="changeData"></setInvite>
				<!-- 工具栏 -->
				<setSetinfor :isShowSet="isShowSet" v-show="isShowSet" :dataList="dataList" @changeData="changeData"></setSetinfor>
				<!-- 可提现金额 -->
				<setwithdrawal :isShowwithdrawal="isShowwithdrawal" v-show="isShowwithdrawal" :dataList="dataList" @changeData="changeData"></setwithdrawal>
				<!-- 图片广告位 -->
				<setPicture :prictrueShow="prictrueShow" v-show="prictrueShow" :dataList="dataList" @changeData="changeData"></setPicture>
			</div>

		</div>

		<!-- 底部保存 -->
		<div class="bottom-save-btn">
			<el-button style="width:220px;margin:0 10px 0 20px" @click="saveFunction(0)" :loading="saveLoading">保存</el-button>
			<el-button type="primary" style="width:220px" @click="saveFunction(1)" :loading="saveLoading">发布</el-button>
		</div>

	</div>
</template>

<script>
	import personalContent from './components/personalComponents/personalContent'; //个人信息栏
	import setPersonalinfor from './components/personalComponents/setPersonalinfor'; //设置个人信息栏
	import personalOrderStyle from './components/personalComponents/personalOrderStyle'; //订单信息栏
	import setOrderinfor from './components/personalComponents/setOrderinfor'; //设置订单信息栏
	import personalInvite from './components/personalComponents/personalInvite'; //我的邀请人
	import setInvite from './components/personalComponents/setInvite'; //我的邀请人
	import personalSetstyle from './components/personalComponents/personalSetstyle'; //工具栏
	import setSetinfor from './components/personalComponents/setSetinfor'; //设置工具栏
	import personalwithdrawal from './components/personalComponents/personalwithdrawal'; //可提现金额
	import setwithdrawal from './components/personalComponents/setwithdrawal'; //设置可提现金额
	import pictureAdvertising from './components/personalComponents/pictureAdvertising'; //图片广告位
	import setPicture from './components/personalComponents/setPicture'; //设置图片广告位
	import {
		mallMemberCenterBaseInfo,
		mallMemberCenterEdit
	} from '@/api/goods'

	export default {
		components: {
			personalContent,
			setPersonalinfor,
			personalOrderStyle,
			setOrderinfor,
			personalInvite,
			setInvite,
			personalSetstyle,
			setSetinfor,
			personalwithdrawal,
			setwithdrawal,
			pictureAdvertising,
			setPicture
		},
		data() {
			return {
				isShowTitle: true,
				isShowInfor: false,
				isShowOrder: false,
				isShowInvite: false,
				isShowSet: false,
				isShowwithdrawal:false,
				prictrueShow:false,
				dataList: {},
				ishowPageItem: true,
				loading: false,
				saveLoading: false,
				dataIsready: false,
				defaultNames: ['购物车', '推手中心', '助力直播', '任务中心', '我的赠品', '收货地址', '个人信息', '联系客服','图片广告位']

			}
		},
		beforeMount() {
			this.getData()
		},
		methods: {
			// 初始化信息
			async getData() {
				try {
					this.loading = true;
					let result = await mallMemberCenterBaseInfo()
					this.dataList = result.Result
					this.dataList.headModule.IsShowVipEntrance = this.dataList.headModule.IsShowVipEntrance ? 1 : 0
					
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
					this.dataIsready = true
				}

			},
			changeAction(changetype) {
				if (changetype == 1) {
					this.isShowTitle = true;
					this.isShowInfor = false;
					this.isShowOrder = false;
					this.isShowInvite = false;
					this.isShowSet = false;
					this.isShowwithdrawal = false
					this.prictrueShow = false
				} else if (changetype == 2) {
					this.isShowTitle = false;
					this.isShowInfor = true;
					this.isShowOrder = false;
					this.isShowInvite = false;
					this.isShowSet = false;
					this.isShowwithdrawal = false
					this.prictrueShow = false
				} else if (changetype == 3) {
					this.isShowTitle = false;
					this.isShowInfor = false;
					this.isShowOrder = true;
					this.isShowInvite = false;
					this.isShowSet = false;
					this.isShowwithdrawal = false
					this.prictrueShow = false
				} else if (changetype == 4) {
					this.isShowTitle = false;
					this.isShowInfor = false;
					this.isShowOrder = false;
					this.isShowInvite = true;
					this.isShowSet = false;
					this.isShowwithdrawal = false
					this.prictrueShow = false
				} else if (changetype == 5) {
					this.isShowTitle = false;
					this.isShowInfor = false;
					this.isShowOrder = false;
					this.isShowInvite = false;
					this.isShowSet = true;
					this.isShowwithdrawal = false
					this.prictrueShow = false
				}else if(changetype == 6){
					this.isShowTitle = false;
					this.isShowInfor = false;
					this.isShowOrder = false;
					this.isShowInvite = false;
					this.isShowSet = false;
					this.isShowwithdrawal = true
					this.prictrueShow = false
				}else if(changetype == 7){
					this.isShowTitle = false;
					this.isShowInfor = false;
					this.isShowOrder = false;
					this.isShowInvite = false;
					this.isShowSet = false;
					this.isShowwithdrawal = false
					this.prictrueShow = true
				}
			},

			//获取改变的值
			changeData(val) {
			},
			// 保存函数
			async saveFunction(type) {
				try {
					this.saveLoading = true
					this.dataList.PageTitle = this.dataList.PageTitle ? this.dataList.PageTitle : '个人中心';
					this.dataList.toolsModule.toolDtoList.map(item => {
						if (!item.ToolName) {
							let indexs = Number(item.Id) - 1
							item.ToolName = this.defaultNames[indexs]
						}
						return item
					})
					let data = {
						mallMemberCenterBaseInfoResult: this.dataList,
						IsPublish: type ? true : false
					}
					let result = await mallMemberCenterEdit(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.getData()
					this.saveLoading = false
				}

			},

		},

	}
</script>

<style lang="less">
	.personalMall {
		padding: 10px;
		
		

		.mallHomeBg {
			display: block;
			width: 100%;
		}

		.content-box {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-justify-content: center;
			justify-content: center;
			margin-bottom: 80px;
		}

		.phonePage {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
			width: 375px;
			max-height: 1150px;
			background-color: #f5f5f5;
			-webkit-box-shadow: 0 0 10px #dbdbdb;
			box-shadow: 0 0 10px #dbdbdb;
		}

		.pageItem {
			cursor: pointer;
			margin-bottom: 15px;
			position: relative;
		}

		.pageNames {
			position: absolute;
			top: 62%;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
		}


		.right-editePage {
			position: relative;
			margin-left: 25px;
		}

		.pageItemactive {
			border: 2px solid #409eff;
		}

		// 右边设置的样式
		.icon-box {
			width: 30px;
			height: 30px;
			background-color: #fff;
			text-align: center;
			line-height: 30px;
			font-size: 20px;
		}

		.setItem {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
		}

		.boxCard-box {
			width: 600px;
			padding: 0 20px;
			margin-left: 5px;
			background-color: #fff;
			position: relative;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

	}
</style>
