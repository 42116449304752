<template>
	<div class="setOrderinfor">
		<div class="setItem" v-if="showOrder" :style="{'margin-top':(dataList.headModule.IsShowWallet?'455px':'285px')}" >
			<div class="icon-box">
				<i class="el-icon-setting" style="color:#409eff"></i>
			</div>
			<el-card class="box-card boxCard-box">
				<div slot="header" class="clearfix">
					<span>订单信息栏</span>
				</div>
				<div class="flex flex -align-center">
					<div class="keyLabel">订单信息栏：</div>
					<el-radio-group v-model="dataList.IsShowOrderModel" class="margin-bottom-10" style="margin-top:5px">
						<el-radio :label="true">显示</el-radio>
						<el-radio :label="false">隐藏</el-radio>
					</el-radio-group>
				</div>
				<div v-if="dataList.IsShowOrderModel">
					<p class="asctions">图标建议尺寸长80px，高80px；透明背景</p>
					<div class="filter-container" v-for="(item,index) in dataList.orderModuleList" :key="index">
						<div class="filter-item" style="width: 105px;text-align: right;">{{item.IconName}} </div>
						<div class="filter-item">
							<img v-if="item.IconUrlComplete " :src="item.IconUrlComplete" alt="" class="imgClass">
						</div>
						<div class="filter-item" @click="uploadImg(item,index)">
							<el-upload :action="imgApi"    :before-upload="beforeUploadfu"  :on-success="handleAvatarSuccessfu" :file-list="fileList" multiple accept="image/png,image/jpeg,image/jpg,image/gif"
							:on-remove="handleRemovefu" :show-file-list="false">
								<el-button type="text" size="small" class="changeImgbtn">更改图标</el-button>
							</el-upload>
						</div>
					</div>
				</div>
			</el-card>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	export default {
		props: {
			isShowOrder: {
				type: Boolean,
				default: false
			},
			dataList: {
				type: Object
			}
		},
		data() {
			return {
				showOrder: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				fileList: [],
				uploadIndex: 0,
			}
		},
		methods: {
			beforeUploadfu(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isJPG) {
					this.$message.error('请上传png,jpeg或gif格式的文件!');
				}
				if (!isLt1M) {
					this.$message.error('图标请控制在1M以内!');
				}
				return isJPG && isLt1M;
			},
			// 上传图片
			uploadImg(item, index) {
				this.uploadIndex = index
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {				
				this.dataList.orderModuleList[this.uploadIndex].IconUrl = file.response[0]
				this.dataList.orderModuleList[this.uploadIndex].IconUrlComplete = this.imgUrl+file.response[0]
				this.$emit('changeData', this.dataList);
				this.$forceUpdate()

			},
			handleRemovefu(file, fileList) {},
		},
		watch: {
			isShowOrder(val) {
				this.showOrder = val
			},

		}
	}
</script>

<style lang="less" scoped>
	.setOrderinfor {
		font-size: 14px;
		.asctions {
			font-size: 12px;
			color: #999;
		}

		.imgClass {
			width: 50px !important;
			height: 50px;

		}
	}
</style>
