<template>
	<div class="setPersonaInvite">
		<!-- 我的邀请人 -->
		<div class="setItem" v-if="isShow" :style="{'margin-top':(dataList.headModule.IsShowWallet?'710px':'580px')}"  >
			<div class="icon-box">
				<i class="el-icon-setting" style="color:#409eff"></i>
			</div>
			<el-card class="box-card boxCard-box">
				<div slot="header" class="clearfix">
					<span>邀请人信息栏</span>
				</div>

				<div class="deployItem">
					<label class="label" style="display: inline-block;width:116px;text-align: right;">邀请人信息：</label>
					<el-radio v-model="dataList.IsShowMyRecommend" :label="true"  @change="radioChange">显示</el-radio>
					<el-radio v-model="dataList.IsShowMyRecommend" :label="false"  @change="radioChange">隐藏</el-radio>
				</div>

			</el-card>
		</div>

	</div>
</template>

<script>
	export default {
		props: {
			isShowInvite: {
				type: Boolean,
				default: false
			},
			dataList: {
				type: Object
			}
		},
		data() {
			return {
				isShow: false,
				fileList: [],
				headModule:{}
			}
		},
		created() {
		},
		beforeMount() {
			
		},
		methods: {
			radioChange(){
				this.$emit('changeData', this.dataList);
			},
		},
		watch: {
			isShowInvite(val) {
				this.isShow = val
			},
		}

	}
</script>

<style lang="less" scoped>
	.setPersonaInvite {
		.setItem{
			margin-top:420px;
			// margin-top:480px;
		}
		.imgClass {
			width: 300px;
			height: 145px;
			vertical-align: top;
		}

		.changeImgbtn {
			margin: 15px 0 0 114px;
			font-size: 14px;
		}

		.asctions {
			font-size: 12px;
			color: #999;
			margin-left: 10px;
		}

	}
</style>
